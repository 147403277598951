import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GetEquipmentState } from './get-equipment.types';

const getEquipmentFeatureSelector = createFeatureSelector<GetEquipmentState>('getEquipment');

export const selectGetEquipmentLoading = createSelector(
  getEquipmentFeatureSelector,
  state => state.updating
);

export const selectGetEquipmentLoaded = createSelector(
  getEquipmentFeatureSelector,
  state => state.updated
);

export const selectGetEquipment = createSelector(
  getEquipmentFeatureSelector,
  state => state.data
);

export const selectGetEquipmentError = createSelector(
  getEquipmentFeatureSelector,
  state => state.error
);
